@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Pragati+Narrow:wght@400;700&family=Work+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Pragati+Narrow:wght@400;700&family=Work+Sans:wght@300&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Pragati Narrow", sans-serif;
  }
}
